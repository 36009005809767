<template>
    <div>
        <cc-loader-full v-if="isLoading" />
        <div class="dashboard-page">
            <div class="dashboard-page-guide">Dashboard</div>
            <div class="dashboard-page-title">Dashboard</div>
            <div class="dashboard-table-left-heade">
                <div class="dashboard-table-left-heade-nunber">
                    <div class="dashboard-table-left-heade-nunber-name">Nº de Revendas</div>
                    <div class="dashboard-table-left-heade-nunber-value">{{ revNumber.value }}</div>
                </div>
                <div class="dashboard-table-left-heade-products">
                    <div class="dashboard-table-left-heade-products-name">Nº de Empresa Compradoras</div>
                    <div class="dashboard-table-left-heade-products-value">{{ cliNumber.value }}</div>
                </div>
                <div class="dashboard-table-left-heade-supliers">
                    <div class="dashboard-table-left-heade-supliers-name">Nº de Fornecedores</div>
                    <div class="dashboard-table-left-heade-supliers-value">{{ supliersNumber.value }}</div>
                </div>
            </div>
            <div class="dashboard-table-main-container" style="">


                <div class="dashboard-table-left">

                    <div class="dashboard-table-container container-size-1" >
                        <div class="dashboard-table-title">Estados com Atuação ( {{ categoriesChartValues.length  }} Estados )</div>
                        <div class="dashboard-segment-title"></div>
                        <div class="dashboard-table-content-rigth">
                            <div class="dashboard-table-content-piechart">
                                <vc-donut :sections="categoriesChartValues" :total="categoriesChartTotal" :size="15"
                                    unit="vh"></vc-donut>
                            </div>
                            <div v-if="categoriesChartValues.length > 0" class="dashboard-table-list">
                                <div v-for="(category, idx) in categoriesChartValues" :key="idx" class="dashboard-table-row">
                                    <div style="display: flex; align-items: center;">
                                        <div class="dashboard-point" :style="'background-color: ' + getPointColor(idx)">
                                        </div>
                                        <div style="margin-left: 0.5vw;">{{ category.name }}</div>
                                    </div>
                                    <div v-if="category.value" class="dashboard-table-row-right2">
                                        {{ category.value.toLocaleString('pt-br', {
                                            style: 'currency', currency: 'BRL'
                                        }) }}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    

                    <div class="dashboard-table-container">
                        <div class="dashboard-table-title">Valor Movimentado por Cotação</div>
                        <div class="dashboard-table-right-1-text">No ano</div>
                        <div class="dashboard-table-right-1-value-row">
                            <div class="dashboard-table-right-1-value-container">
                                <div class="dashboard-table-right-1-value"
                                    :style="'width: ' + (valueRequest.all? valueRequest.all.percent : '0') + '%'"></div>
                            </div>
                            <div v-if="valueRequest.all && valueRequest.all.value" class="dashboard-table-row-right">{{ valueRequest.all.value.toLocaleString('pt-br', {
                                style:
                                    'currency', currency: 'BRL'
                            }) }}</div>
                        </div>

                        <div class="dashboard-table-right-1-text">Mês Anterior</div>
                        <div  class="dashboard-table-right-1-value-row">
                            <div class="dashboard-table-right-1-value-container">
                                <div class="dashboard-table-right-1-value"
                                    :style="'width: ' + (valueRequest.last ? valueRequest.last.percent : '0') + '%'"></div>
                            </div>
                            <div v-if="valueRequest.last && valueRequest.last.value" class="dashboard-table-row-right">{{ valueRequest.last.value.toLocaleString('pt-br', {
                                style:
                                    'currency', currency: 'BRL'
                            }) }}</div>
                        </div>

                        <div class="dashboard-table-right-1-text">Mês atual</div>
                        <div  class="dashboard-table-right-1-value-row">
                            <div class="dashboard-table-right-1-value-container">
                                <div class="dashboard-table-right-1-value"
                                    :style="'width: ' + (valueRequest.now ? valueRequest.now.percent : '0') + '%'"></div>
                            </div>
                            <div v-if="valueRequest.now && valueRequest.now.value" class="dashboard-table-row-right">{{ valueRequest.now.value.toLocaleString('pt-br', {
                                style:
                                    'currency', currency: 'BRL'
                            }) }}</div>

                        </div>
                    </div>
                </div>

                <div class="dashboard-table-left">
                    
                    <div class="dashboard-table-container container-size-1" >
                        <div class="dashboard-table-title">Segmentos de Atuação</div>
                        <div class="dashboard-segment-title">
                            <div class="dashboard-segment-select-container">
                                <div class="dashboard-bar-table-text">Ano Inicio:</div>
                                <StandardInput style="width: 10vw" class="dashboard-ranks-select" :action="updateSegmentYearInit" :placeholder="yearInit"  :type="'select'" :list="yearList"/>
                                <div class="dashboard-bar-table-text">Ano Fim:</div>
                                <StandardInput style="width: 10vw" class="dashboard-ranks-select" :action="updateSegmentYearEnd" :placeholder="yearEnd"  :type="'select'" :list="yearList"/>
                                <StandardButton style="min-width: 0; height: 6vh;" iconName="search" :action="segmentYearButtonClick" :text="'Filtrar'"/>
                            </div>
                        </div>
                        
                        <div class="dashboard-table-content-rigth">
                            <div class="dashboard-table-content-piechart">
                                <vc-donut v-if="bigValue" :sections="segmentChartValues" :total="bigValue" :size="15" unit="vh"></vc-donut>
                            </div>
                            <div v-if="segmentChartValues.length > 0" class="dashboard-table-list">
                                <div v-for="(store, idx) in segmentChartValues" :key="idx" class="dashboard-table-row">
                                    <div style="display: flex; align-items: center;">
                                        <div class="dashboard-point" :style="'background-color: ' + getPointColor(idx)">
                                        </div>
                                        <div style="margin-left: 0.5vw;">{{ store.name }}</div>
                                    </div>
                                    <div v-if="store.value" class="dashboard-table-row-right2">
                                        {{ store.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard-table-container">
                        <div class="dashboard-table-title">Valor Movimentado por Pedido Direto</div>
                        <div class="dashboard-table-right-1-text">No ano</div>
                        <div  class="dashboard-table-right-1-value-row">
                            <div class="dashboard-table-right-1-value-container">
                                <div class="dashboard-table-right-1-value" style="background-color: #FFB888;"
                                    :style="'width: ' + (valueDirectRequest.all ? valueDirectRequest.all.percent : '0') + '%'"></div>
                            </div>
                            <div v-if="valueDirectRequest.all && valueDirectRequest.all.value" class="dashboard-table-row-right">{{ valueDirectRequest.all.value.toLocaleString('pt-br', {
                                style:
                                    'currency', currency: 'BRL'
                            }) }}</div>
                        </div>

                        <div class="dashboard-table-right-1-text">Mês Anterior</div>
                        <div  class="dashboard-table-right-1-value-row">
                            <div class="dashboard-table-right-1-value-container">
                                <div class="dashboard-table-right-1-value" style="background-color: #FFB888;"
                                    :style="'width: ' + (valueDirectRequest.last ? valueDirectRequest.last.percent : '0') + '%'"></div>
                            </div>
                            <div v-if="valueDirectRequest.last && valueDirectRequest.last.value" class="dashboard-table-row-right">{{ valueDirectRequest.last.value.toLocaleString('pt-br', {
                                style:
                                    'currency', currency: 'BRL'
                            }) }}</div>
                        </div>

                        <div class="dashboard-table-right-1-text">Mês atual</div>
                        <div  class="dashboard-table-right-1-value-row">
                            <div class="dashboard-table-right-1-value-container">
                                <div class="dashboard-table-right-1-value" style="background-color: #FFB888;"
                                    :style="'width: ' + (valueDirectRequest.now ? valueDirectRequest.now.percent : '0') + '%'"></div>
                            </div>
                            <div v-if="valueDirectRequest.now && valueDirectRequest.now.value" class="dashboard-table-row-right">{{ valueDirectRequest.now.value.toLocaleString('pt-br', {
                                style:
                                    'currency', currency: 'BRL'
                            }) }}</div>

                        </div>
                    </div>
                </div>

            </div>


            <div class="dashboard-table-right">
                <div class="dashboard-table-right-2" v-if="resp1">
                    <div class="dashboard-table-title">Movimentação por Ano</div>
                    <div class="dashboard-bar-table">
                        <div v-for="(bar, idx) in resp1.pedidosPorAno" :key="idx" class="dashboard-bar-table-colum-colum">
                            <div v-if="bar.total_pedido" class="dashboard-bar-table-text">{{bar.total_pedido.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                            <div class="dashboard-bar-table-colum" :style="'height: ' +bar.percent+ '%'"></div>
                        </div>
                    </div>
                    <div class="dashboard-bar-table-legends" style="font-weight: 500; font-size: 1.3em; color: #A1A1A1;">
                        <div v-for="(bar, idx) in resp1.pedidosPorAno" :key="idx">
                            {{bar.ano}}
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="dashboard-main-chart-container">
                <div class="dashboard-table-title" style="margin-bottom: 2vh;">Movimentação por ano (em milhões)</div>
                <canvas id="planet-chart"></canvas>
            </div> -->


            <div class="dashboard-ranks-container">
                
                <div class="dashboard-ranks2">
                    <div class="dashboard-ranks-title">
                        <div style="font-size: 1.7em;">Movimentação por Estado</div>
                        <div class="dashboard-ranks-select-container">
                            <StandardInput class="dashboard-ranks-select" :action="updateYearInit" :placeholder="yearInit" title="Ano de inicio" :type="'select'" :list="yearList"/>
                            <StandardInput class="dashboard-ranks-select" :action="updateYearEnd" :placeholder="yearEnd" title="Ano de fim" :type="'select'" :list="yearList"/>
                            <StandardButton iconName="search" :action="yearButtonClick" :text="'Filtrar'"/>
                        </div>
                    </div>
                    <div class="dashboard-ranks-header" style="color: #30AA4C;">
                        <div class="dashboard-ranks-header-rank"></div>
                        <div class="dashboard-ranks-header-suplier">Estado</div>
                        <div class="dashboard-ranks-header-clients">Clientes/Filiais</div>
                        <div class="dashboard-ranks-header-supliers">Fornecedores</div>
                        <div class="dashboard-ranks-header-resales">Revendas</div>
                        <div class="dashboard-ranks-header-quantity">Valor</div>
                    </div>
                    <div :class="getSpecialBackground(idx)" class="dashboard-ranks-row"
                        v-for="(product, idx) in stateRanks" :key="idx">
                        <div class="dashboard-ranks-header-rank"></div>
                        <div class="dashboard-ranks-header-suplier">{{ product.est_nome }}</div>
                        <div class="dashboard-ranks-header-clients">{{ product.qtd_cliente }}</div>
                        <div class="dashboard-ranks-header-supliers">{{ product.qtd_fornecedor }}</div>
                        <div class="dashboard-ranks-header-resales">{{ product.qtd_revenda }}</div>
                        <div v-if="product.valor_total_pedido" class="dashboard-ranks-header-quantity">{{ product.valor_total_pedido.toLocaleString('pt-br', {
                                style:
                                    'currency', currency: 'BRL'
                            }) }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DashboardService from '@/services/v3/DashboardService.js'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardButton from '@/components/ui/buttons/StandardButton.vue';
import Chart from 'chart.js'

export default {
    name: 'DashboardPage',
    components:{
        StandardInput,
        StandardButton
    },
    data() {
        return {
            sections: [],
            stateRanks: [],
            valueRequest: { all: undefined, now: undefined, last: undefined },
            valueDirectRequest: { all: undefined, now: undefined, last: undefined },
            revNumber: { rof: 0, value: 0 },
            cliNumber: { rof: 0, value: 0 },
            supliersNumber: { rof: 0, value: 0 },
            dashboardService: new DashboardService(),
            categoriesChartValues: [],
            colorList: [
                "#FF0000",  
                "#00FF00",  
                "#0000FF", 
                "#FFFF00",  
                "#FF00FF",  
                "#00FFFF", 
                "#800000",  
                "#008000",  
                "#000080", 
                "#FFC0CB", 
                "#800080",  
                "#FFD700",  
                "#008080",  
                "#A52A2A",  
                "#FFFFF0",  
                "#F0E68C",  
                "#F08080",  
                "#FAEBD7",  
                "#D2691E",  
                "#FF7F50",  
                "#FFFACD",  
                "#7FFF00", 
                "#BA55D3",  
                "#DAA520",  
                "#C71585",  
                "#FF8C00",  
                "#FF69B4"   
            ],
            resp1: undefined,
            segmentList: [],
            segmentChartValues: [],
            categoriesChartTotal: undefined,
            isLoading: true,
            bigValue: undefined,
            yearList: [],
            yearInit: new Date().getFullYear(),
            yearEnd: new Date().getFullYear(),
            segmentYearInit: new Date().getFullYear(),
            segmentYearEnd: new Date().getFullYear(),
            priceInfo: {
                type: "line",
                data: {
                    labels: [],
                    datasets: [
                        {
                            label: "Média preço máximo",
                            data: [],
                            fill: false,
                            borderColor: "#E63535",
                            tension: 0.2,
                            borderWidth: 5
                        }
                    ]
                },
                options: {
                    responsive: true,
                    lineTension: 1,
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    padding: 25
                                }
                            }
                        ]
                    },
                    plugins: {
                        legend: {
                            display: false,
                            title: {
                                display: false
                            }
                        }
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    hover: {
                        mode: 'index',
                        intersect: false
                    }
                }
            }
        };
    },
    methods: {
        getPointColor(idx) {
            return this.colorList[idx]
        },
        async loadNumbers() {
            for (let i = 0.1; i <= 1; i = parseFloat((i + 0.01).toFixed(2))) {
                await this.sleep(10)
                this.revNumber.value = (this.revNumber.rof * i).toFixed(0)
                this.cliNumber.value = (this.cliNumber.rof * i).toFixed(0)
                this.supliersNumber.value = (this.supliersNumber.rof * i).toFixed(0)
            }
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async loadCategoryChart() {
            let bigValue = 0
            this.stateRanks.forEach(element => {
                 bigValue += element.valor_total_pedido
            });
            this.categoriesChartTotal = bigValue
            let statesList = [...this.stateRanks]
            statesList.sort((a, b) => { return b.valor_total_pedido - a.valor_total_pedido  })
            for (let i = 0; i < statesList.length; i++) {
                this.categoriesChartValues.push({
                    name: statesList[i].est_nome,
                    percentTop: statesList[i].valor_total_pedido,
                    value: 0,
                    color: this.getPointColor(i)
                })
            }
            for (let i = 0.1; i <= 1; i = parseFloat((i + 0.01).toFixed(2))) {
                await this.sleep(10)
                this.categoriesChartValues.forEach(element => {
                    element.value = element.percentTop * i
                });
            }
        },
        async loadSegmentChart() {
            this.segmentList.sort((a, b) => { return b.total - a.total  })
            this.segmentChartValues = []
            this.segmentList.forEach((element, idx) => {
                this.segmentChartValues.push({
                    name: element.ramo_descricao,
                    value: 0,
                    top: element.total,
                    color: this.getPointColor(idx)
                })
            })
            let bigValue = 0
            this.segmentChartValues.forEach(element => {
                bigValue += element.top
            })
            this.bigValue = bigValue
            for (let i = 0.1; i <= 1; i = parseFloat((i + 0.01).toFixed(2))) {
                await this.sleep(10)
                this.segmentChartValues.forEach(element => {
                    element.value = element.top * i
                });
            }
        },
        load() {
            this.supliersNumber.rof = this.resp1.qtd_fornecedorQtd
            this.cliNumber.rof = this.resp1.qtd_clienteQtd
            this.revNumber.rof = this.resp1.qtd_revenda
            this.loadNumbers()
            this.valueRequest.all = {
                percent: 100,
                value: this.resp1.pedidosResumo.ano_atual
            }
            this.valueRequest.last = {
                percent: this.resp1.pedidosResumo.mes_anterior / this.resp1.pedidosResumo.ano_atual * 100,
                value: this.resp1.pedidosResumo.mes_anterior
            }
            this.valueRequest.now = {
                percent: this.resp1.pedidosResumo.mes_atual / this.resp1.pedidosResumo.ano_atual * 100,
                value: this.resp1.pedidosResumo.mes_atual
            }
            this.valueDirectRequest.all = {
                percent: 100,
                value: this.resp1.pedidosDireto.ano_atual
            }
            this.valueDirectRequest.last = {
                percent: this.resp1.pedidosDireto.mes_anterior / this.resp1.pedidosDireto.ano_atual * 100,
                value: this.resp1.pedidosDireto.mes_anterior
            }
            this.valueDirectRequest.now = {
                percent: this.resp1.pedidosDireto.mes_atual / this.resp1.pedidosDireto.ano_atual * 100,
                value: this.resp1.pedidosDireto.mes_atual
            }
            // this.loadChart()
            this.loadCategoryChart()
            this.loadSegmentChart()
            this.loadBigChart()
            this.isLoading = false
        },
        updateYearInit(year) {
            this.yearInit = year
        },
        updateYearEnd(year) {
            this.yearEnd = year
        },
        updateSegmentYearInit(year) {
            this.segmentYearInit = year
        },
        updateSegmentYearEnd(year) {
            this.segmentYearEnd = year
        },
        yearButtonClick () {
            this.filterStates()
        },
        segmentYearButtonClick () {
            this.filterSegments()
        },
        filterSegments () {
            this.isLoading = true
            this.dashboardService.getInfo3(this.segmentYearInit, this.segmentYearEnd).then(resp => {
                this.segmentList = resp.data
                this.loadSegmentChart()
                this.isLoading = false
            })
        },
        filterStates () {
            this.isLoading = true
            this.dashboardService.getInfo2(this.yearInit, this.yearEnd).then(resp => {
                this.stateRanks = resp.data
                this.isLoading = false
            })
        },
        loadBigChart () {
            let bigValue = 0
            this.resp1.pedidosPorAno.forEach(element => {
                if (element.total_pedido > bigValue) bigValue = element.total_pedido
                element.percent = 0
            });
            
            setTimeout(() => {
                this.resp1.pedidosPorAno.forEach(element => {
                    element.percent = element.total_pedido / bigValue * 100
                })
                this.$forceUpdate()
            }, 500);
        }
    },
    async beforeMount() {
        this.sections = []
        // this.dashboardService.getInfo().then(resp => {
        //     this.resp1 = resp.data
        //     this.dashboardService.getInfo2().then(resp => {
        //         this.stateRanks = resp.data
        //         this.load()
        //     })
        // })
        this.yearList = [
            {text: this.yearInit, value: this.yearInit},
            {text: this.yearInit-1, value: this.yearInit-1},
            {text: this.yearInit-2, value: this.yearInit-2},
            {text: this.yearInit-3, value: this.yearInit-3},
            {text: this.yearInit-4, value: this.yearInit-4},
        ]
        const request1 = this.dashboardService.getInfo()
        const request2 = this.dashboardService.getInfo2()
        const request3 = this.dashboardService.getInfo3()
        const [resp1, resp2, resp3] = await Promise.all([request1, request2, request3])
        this.resp1 = resp1.data
        this.stateRanks = resp2.data
        this.segmentList = resp3.data
        this.load()
    }
}
</script>

<style scoped>
.dashboard-page {
    padding-right: 5vw;
    padding-bottom: 10vh;
    font-size: 1vw;
}

.dashboard-page-guide {
    font-weight: 400;
    font-size: 1em;
    color: #ABABAB;
}

.dashboard-page-title {
    font-weight: 600;
    font-size: 2.25em;
    color: #605F5F;
    margin-top: 1vh;
}

.dashboard-table-left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 3vh;
    margin-top: 3vh;
}
.dashboard-segment-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1vh;
    height: 6vh;
}

.dashboard-table-left-heade {
    display: flex;
    justify-content: space-between;
}

.dashboard-table-left-heade-nunber {
    background: rgba(23, 162, 184, 0.1);
    border-radius: 20px;
    width: 30%;
    padding: 1vh 0 1vh 1vw;
    color: #17A2B8;
}

.dashboard-table-left-heade-nunber-name {
    font-weight: 500;
    font-size: 1.3em;
    letter-spacing: 0.15px;
}
.dashboard-table-main-container{
    display: flex; 
    justify-content: space-between;
}

.dashboard-ranks-select{
    width: 15vw;
}

.dashboard-table-left-heade-nunber-value {
    font-weight: 400;
    font-size: 2.25em;
    letter-spacing: 0.15px;
}

.dashboard-table-left-heade-products {
    background: rgba(42, 205, 114, 0.1);
    border-radius: 20px;
    width: 30%;
    padding: 1vh 0 1vh 1vw;
    color: #30AA4C;
}

.dashboard-table-left-heade-products-name {
    font-weight: 500;
    font-size: 1.3em;
    letter-spacing: 0.15px;
}
.dashboard-main-chart-container{
    margin-top: 6vh;
    border-radius: 8px;
    padding: 1vh 1vw;
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
}

.dashboard-table-left-heade-products-value {
    font-weight: 400;
    font-size: 2.25em;
    letter-spacing: 0.15px;
}

.dashboard-table-left-heade-supliers {
    background: rgba(255, 184, 136, 0.1);
    border-radius: 20px;
    width: 30%;
    padding: 1vh 0 1vh 1vw;
    color: var(--primary-color);
}

.dashboard-table-left-heade-supliers-name {
    font-weight: 500;
    font-size: 1.3em;
    letter-spacing: 0.15px;
}

.dashboard-table-left-heade-supliers-value {
    font-weight: 400;
    font-size: 2.25em;
    letter-spacing: 0.15px;
}

.dashboard-table-container {
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1vh 1vw;
    width: 52vw;
}

.dashboard-table-title {
    font-weight: 600;
    font-size: 1.8em;
    color: #505050;
}

.dashboard-table-content-rigth {
    display: flex;
    height: 78%;
}

.dashboard-table-content-piechart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.dashboard-table-list {
    flex: 1;
    overflow-y: auto;
    height: 100%;
}

.dashboard-table-list::-webkit-scrollbar {
    height: 1vh;
    background: transparent;
    width: 1vw;
}

.dashboard-table-list::-webkit-scrollbar-thumb:vertical {
    background: lightgray;
    border-radius: 10px;
}

.dashboard-table-row {
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0.5vh 0.5vw;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1vh;
    margin-right: 0.5vw;
    font-size: 1.3em;
}

.dashboard-point {
    border-radius: 50%;
    height: 1vh;
    width: 1vh;
}

.dashboard-table-row-right {
    margin-left: 2vw;
    font-weight: 400;
    font-size: 1.3em;
    color: #606060;
}

.dashboard-table-row-right2 {
    margin-right: 2vw;
    font-weight: 400;
    color: #606060;
}




.dashboard-table-right-1-text {
    font-weight: 400;
    font-size: 1.3em;
    color: #505050;
}
.dashboard-table-right{
    flex: 1;
    display: flex;
    flex-direction: column;
}
.dashboard-bar-table{
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: 30vh;
}
.container-size-1{
    height: 60vh;
}
.dashboard-bar-table-colum-colum{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.dashboard-table-right-2{
    flex: 1;
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 11.3383px;
    padding: 1vh 1vw;
    margin-top: 3vh;
}
.dashboard-bar-table-colum{
    background-color: #FFB888;
    border-radius: 10px 10px 0 0;
    width: 1.1vw;
    height: 0;
    transition: height 1s;
}
.dashboard-bar-table-text{
    font-weight: 500;
    font-size: 1.3em;
    margin-right: -0.5vw;
    color: #A1A1A1;
}
.dashboard-bar-table-legends{
    display: flex;
    justify-content: space-around;
    margin-top: 2vh;
}

.dashboard-table-right-1-value-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-table-right-1-value-container {
    width: 70%;
    background-color: #F7F7F7;
    border-radius: 10px;
    height: 0.6em;
}

.dashboard-table-right-1-value {
    background-color: #93CECE;
    border-radius: 10px;
    height: 0.6em;
    width: 0;
    transition: width 2s;
}


.dashboard-ranks-container {
    display: flex;
    margin-top: 3vh;
    flex-direction: column;
    gap: 5vh;
}



.dashboard-ranks2 {
    flex: 1;
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.dashboard-ranks-title {
    padding: 1vh 1vw;
    font-weight: 600;
    color: #505050;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-ranks-select-container{
    display: flex;
    gap: 3vw;
    align-items: flex-end;
}
.dashboard-segment-select-container{
    display: flex;
    gap: 2vw;
    align-items: center;
}

.dashboard-ranks-header {
    display: flex;
    background: #FFFEFC;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 1vh 0;
    font-weight: 400;
    font-size: 1em;
}

.dashboard-ranks-header-suplier {
    flex: 1;
    text-transform: uppercase;
}

.dashboard-ranks-header-quantity {
    width: 15%;
}

.dashboard-ranks-header-clients {
    width: 15%;
}

.dashboard-ranks-header-supliers {
    width: 15%;
}

.dashboard-ranks-header-resales {
    width: 15%;
}


.dashboard-ranks-header-rank {
    width:5%;
}

.page-table-line-special {
    background: #F7F7F7;
}

.dashboard-ranks-row {
    display: flex;
    align-items: center;
    padding: 0.5vh 0;
    margin-top: 1vh;
    font-weight: 400;
    font-size: 1.2em;
    color: #606060;
    height: 7vh;
}




@media only screen and (max-width: 1000px) {
    
    .dashboard-table-main-container{
        flex-direction: column;
        gap: 2vh;
    }
    .dashboard-ranks-header-supliers{
        width: 20%;
    }
    .dashboard-ranks-header-quantity{
        width: 25%;
    }
    .dashboard-ranks-header-rank{
        width: 1%;
    }
    .dashboard-page {
        font-size: 1.5vw;
    }

    .dashboard-table-left {
        margin-right: 0;
        width: 100%;
        flex-direction: column;
    }

    .dashboard-table-container {
        width: 100%;
    }

    .dashboard-bar-table-text {
        font-size: 0.8em;
    }

    .dashboard-table-right-1 {
        margin-top: 2vh;
    }


    .dashboard-table-right-2 {
        flex: none;
        height: 40vh;
    }

    .dashboard-ranks-container {
        flex-direction: column;
    }

    .dashboard-ranks1 {
        width: 100%;
        margin-top: 2vh;
    }

    .dashboard-ranks2 {
        width: 100%;
        margin-top: 2vh;
    }
}

@media only screen and (max-width: 700px) {
    .dashboard-page {
        font-size: 2vw;
        padding: 0 15px 30px 0;
    }

    .dashboard-bar-table-colum {
        width: 2vw;
    }
}

@media only screen and (max-width: 500px) {
    .dashboard-page {
        font-size: 2.5vw;
    }

    .dashboard-table-left-heade-products {
        padding: 1vh 0 0 2vh;
    }

    .dashboard-table-left-heade-supliers {
        padding: 1vh 0 0 2vh;
    }

    .dashboard-table-left-heade-nunber {
        padding: 1vh 0 0 2vh;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-page {
        font-size: 3vw;
    }
}</style>