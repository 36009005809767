import http from './Http';
import Service from './Service';

export default class StoresService extends Service {

    getStores(cli_id) {
        return http.get('loja?cliente_id=' + cli_id, {headers: this.getHeaders(), timeout: 1200000 });
    }
}

