<template>
    <div class="no-data-available">
        {{ text }} 
        <i class="fa fa-check-circle-o" />
    </div>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Nenhuma informação disponível'
        }
    }
}
</script>

<style lang="scss" scoped>
    .no-data-available {
        text-align: center;
        font-size: 3rem;
        padding: 3rem 1rem;
        font-weight: bolder;
        background-color: white;
        color: gray;
        opacity: 0.3;
        font-family: sans-serif;
        i {
            display: block;
            font-size: 6rem;
            padding-top: 2rem;
        }
    }   
</style>