export default class TokenService {

  constructor() {
    this.token = 'token';
    this.tokenUser = 'usuario';
  }

  exists() {
    return this.get() === null;
  }

  get(key = this.token) {
    return localStorage.getItem( key );
  }

  getUser() {
    return JSON.parse(localStorage.getItem( this.tokenUser ));
  }

  put(key, value) {
    localStorage.setItem( key, value);
  }

  save( token ) {
    localStorage.setItem( this.token, token);
  }

  clear() {
    localStorage.removeItem( this.token );
    localStorage.removeItem( this.tokenUser );
  }
}
