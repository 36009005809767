import Router from 'vue-router'
// import SigninPage from '@/pages/signin/signin.page'
const SigninPage = () => import('@/pages/signin/signin.page')

// import HomePage from '@/pages/home/home.page'
const HomePage = () => import('@/pages/home/home.page')

const NewRequestPage = () => import('@/pages/monitors/new/new.page')
// import NewRequestPage from '@/pages/monitors/new/new.page'

const RequestWinners = () => import('@/pages/monitors/winners/winners.page')
//import RequestWinners from '@/pages/monitors/winners/winners.page'

const MonitorsPage = () => import('@/pages/monitors/monitors.page')
// import MonitorsPage from '@/pages/monitors/monitors.page'

const RequestDetailsPage = () => import('@/pages/monitors/details/details.page')
//import RequestDetailsPage from '@/pages/monitors/details/details.page'


const RequestMatrixDetailsPage = () => import('@/pages/monitors/details/details-matrix.page')
//import RequestMatrixDetailsPage from '@/pages/monitors/details/details-matrix.page'

//import RequestProvidersWithoutOffer from '@/pages/monitors/providers/without-offer.page'
const RequestProvidersWithoutOffer = () => import('@/pages/monitors/providers/without-offer.page')

const DirectRequest = () => import('./pages/orders/show.page')
//import DirectRequest from './pages/orders/show.page'

const NotFoundPage = () => import('@/pages/errors/not-found.page')
//import NotFoundPage from '@/pages/errors/not-found.page'

const ProvidersPage = () => import('@/pages/providers/index.page')
//import ProvidersPage from '@/pages/providers/index.page'

const SellersPage = () => import('@/pages/providers/sellers.page')
//import SellersPage from '@/pages/providers/sellers.page'

const ProviderProductsPage = () => import('@/pages/providers/products.page')
//import ProviderProductsPage from '@/pages/providers/products.page'

const DirectPuchasesPage = () => import('@/pages/orders/index.page')
//import DirectPuchasesPage from '@/pages/orders/index.page'

const ClientsPage = () => import('@/pages/clients/index.page')
//import ClientsPage from '@/pages/clients/index.page'

const SysConfPage = () => import('@/pages/sysconf/index.page')
//import SysConfPage from "@/pages/sysconf/index.page";

const AnalyticsPage = () => import('@/pages/clients/analytics.page')
//import AnalyticsPage from "@/pages/clients/analytics.page";

const ResalesPage = () => import('@/pages/resales/resales.page')
//import ResalesPage from '@/pages/resales/resales.page'

const AttendancesPage = () => import('@/pages/clients/attendances.page')
//import AttendancesPage from "@/pages/clients/attendances.page";

const InvitationsPage = () => import('@/pages/clients/invitations.page')
//import InvitationsPage from '@/pages/clients/invitations.page'

const ProductsPage = () => import('@/pages/products/products.page')
//import ProductsPage from '@/pages/products/products.page'

const ProductMaintenancePage = () => import('@/pages/ProductMaintenancePage.vue')
//import ProductMaintenancePage from '@/pages/ProductMaintenancePage.vue'

const ReportsPage = () => import('@/pages/reports/reports.page')
//import ReportsPage from '@/pages/reports/reports.page'

const SettingsPage = () => import('@/pages/user/settings.page')
//import SettingsPage from '@/pages/user/settings.page'

const ResaleSettingsPage = () => import('@/pages/user/resale-settings.page')
//import ResaleSettingsPage from '@/pages/user/resale-settings.page'

const ClientRegistrationRequestPage = () => import('@/pages/clients/registration-requests.page')
//import ClientRegistrationRequestPage from '@/pages/clients/registration-requests.page'

//import ProviderRegistrationRequestPage from '@/pages/providers/registration-requests.page'
const ProviderRegistrationRequestPage = () => import('@/pages/providers/registration-requests.page')

const ClientLead = () => import('@/pages/providers/registration-lead')

const CompraDireta = () => import('./components/cliente/relatorios/CompraDireta')
//import CompraDireta from './components/cliente/relatorios/CompraDireta'

const Contracts = () => import('@/pages/contracts/contractsPage')
//import Contracts from '@/pages/contracts/contractsPage'

const FoodServiceBrands = () => import('@/pages/products/FoodServiceBrands.vue')

import DashboardPage from '@/pages/DashboardPage/DashboardPage.vue'


let router = new Router({
    mode: "history",
    hash: false,
    routes: [
        { path: "/", name: "home", component: HomePage },
        { path: "/Login/", name: "login", component: SigninPage },
        { path: "/cliente/", name: "cliente", component: HomePage },
        { path: "/cliente/cotacao/nova", name: "cliente-new-request", component: NewRequestPage },
        { path: "/cliente/monitores/:tab", name: "monitors-page", component: MonitorsPage, props: true },
        { path: "/cliente/monitores/vencedores/:id", name: "cliente-monitores-vencedores", component: RequestWinners },
        { path: "/cliente/monitores/pedidos/:id", name: "cliente-monitores-pedido", component: DirectRequest },
        { path: "/cliente/vencedores/:id/vendedor/:sellerid/loja/:sid/fornecedor/:providerid", name: "lancamento-cliente-monitores-vencedores", component: RequestDetailsPage, props: true },
        { path: "/cliente/vencedores/:id/vendedor/:sellerid/fornecedor/:providerid", name: "request-matrix-overview", component: RequestMatrixDetailsPage, props: true },
        { path: "/cliente/monitores/:id/naodigitados", name: "nao-digitados-cotacao", component: RequestProvidersWithoutOffer },
        { path: "/relatorios", name: "relatorios", component: ReportsPage, props: true },
        { path: "/settings", name: "settings", component: SysConfPage, props: true },
        { path: "/pedidos", name: "orders", component: DirectPuchasesPage },
        { path: "/fornecedores", name: "providers", component: ProvidersPage },
        { path: "/fornecedores/vendedores", name: "providers-sellers", component: SellersPage },
        { path: "/fornecedores/solicitacoes", name: "provider-registration-requests", component: ProviderRegistrationRequestPage },
        { path: "/fornecedores/produtos", name: "provider-products", component: ProviderProductsPage },
        { path: "/products/maintenance", name: "product-maintenance", component: ProductMaintenancePage },
        { path: "/clientes/solicitacoes", name: "clients-registration-requests", component: ClientRegistrationRequestPage },
        { path: "/clientes/lead", name: "clients-lead", component: ClientLead },
        { path: "/clientes/acompanhamentos", name: "clients-attendances", component: AttendancesPage },
        { path: "/clientes/", name: "clients", component: ClientsPage },
        { path: "/produtos/marcas", name: "productsBrands", component: FoodServiceBrands },
        { path: "/clientes/analitico", name: "analytic-clients", component: AnalyticsPage },
        { path: "/resales/", name: "resales", component: ResalesPage },
        { path: "/produtos/", name: "products", component: ProductsPage },
        { path: "/compradireta/", name: "Compra-Direta", component: CompraDireta },
        { path: "/configuracoes/", name: "user-settings", component: SettingsPage },
        { path: "/revenda/configuracoes", name: "resale-settings", component: ResaleSettingsPage, props: true },
        { path: "/convites/", name: "invitations", component: InvitationsPage },
        { path: "/contracts/", name: "contracts", component: Contracts },
        { path: "404", name: "404", component: NotFoundPage },
        { path: "/dashboard/", name: 'Dashboard', component: DashboardPage },
        {
            path: "*",
            redirect: { name: "404" }
        }
    ]
});

import { store } from '@/store/store'
router.beforeEach((to, from, next) => {
	store.dispatch('user/load_user')
	next()
})

export default router;
