export default class StorageService {

    static get(key) {
        return JSON.parse(localStorage.getItem(key))
    }

    static get_string(key) {
        return localStorage.getItem(key)
    }

    static token() {
        return localStorage.getItem('token')
    }

    static tpc() {
        return JSON.parse(localStorage.getItem('tpc_id'))
    }

    static set(key, object) {
        if(object != null)localStorage.setItem(key, JSON.stringify(object))
    }

    static add_to_array(key, object) {
        let arr = this.get(key);
        if(!(arr && arr instanceof Array)) {
            arr  = []
        }
        if(!arr.includes(object)) {
            arr.push(object)
        }
        localStorage.setItem(key, JSON.stringify(arr))
    }

    static remove_from_array(key, object) {
        let arr = this.get(key);
        if(!(arr && arr instanceof Array)) {
            arr  = []
        }
        if(arr.includes(object)) {
            arr.splice([arr.indexOf(2)], 1)
        }
        localStorage.setItem(key, JSON.stringify(arr))
    }
}