import http from "./http";
import Service from "../Service";

export default class DashboardService extends Service {
    getInfo() {
        return http.get('admin/dashboard/new/', {headers: this.getHeaders()})
    }
    getInfo2(yearInit, yearEnd) {
        let url = ''
        if (yearInit && yearEnd) url = 'admin/dashboard/new/states/?' + 'start_year=' + yearInit + '&end_year=' + yearEnd
        else url = 'admin/dashboard/new/states'
        return http.get(url, {headers: this.getHeaders()})
    }
    getInfo3(yearInit, yearEnd) {
        let url = ''
        if (yearInit && yearEnd) url = 'admin/dashboard/new/segmento/?' + 'start_year=' + yearInit + '&end_year=' + yearEnd
        else url = 'admin/dashboard/new/segmento'
        return http.get(url, {headers: this.getHeaders()})
    }
}