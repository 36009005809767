import http from './Http';
import Service from './Service';

/**
 * @type QuotationService
 */
export default class AuthService extends Service {

    get_user() {
        return http.get('getUser', {
            headers: this.getHeaders(),
            timeout: 200000
        })
    }

}
