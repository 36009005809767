// import ClientServiceV3 from "@/services/v3/ClientService.js";
import axios from 'axios';
import { PROJECT_ENV } from '@/constants/constants'

const state = {
    data: { }
};

const mutations = {
    SET_DADOS(state, data) {
        state.data = data;
    }
};


const actions = {
    async fetchDados({ commit }) {
        try {
            // console.log('try?', PROJECT_ENV);
            if (PROJECT_ENV != 'prod') {
                const response = await axios
                    .get('https://hml.api.clubdacotacao.com.br/v3/api/external/label/themes');
                commit('SET_DADOS', response.data);
            } else {
                const response = await axios
                    .get('https://api.clubdacotacao.com.br/v3/api/external/label/themes');
                commit('SET_DADOS', response.data);
            }
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
        }
    }
};

export default {
    state,
    mutations,
    actions
};
