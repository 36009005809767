<template>
  <div>
      <label for="" v-if="label">{{ label }}</label>
      <input :value="value" @input="updateValue" class="form-control" v-bind="$attrs" :class="classes">
  </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        classes: String,
        label: {
            type: String
        },
        value: [Number, String]
    },
    methods: {
        updateValue(event) {
            this.$emit('input', event.target.value)
        }
    }
}
</script>

<style lang="scss" scoped>
    input {
        min-height: 40px;
        color: #495057 !important;
        padding: 25px 10px;
        border-radius: 10px;
    }
    input:focus {
        outline: 3px solid  #FF7110;
    }
</style>
