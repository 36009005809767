var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"label label-default",class:{
      'pedidoGerado clickable pedido-gerado': _vm.status == 'PEDIDO GERADO' || _vm.status == 'ORDERED',
      'label-warning em-digitacao': _vm.status == 'EM DIGITACAO' || _vm.status == 'TYPING',
      'label-success digitada': _vm.status == 'DIGITADA' || _vm.status == 'COTACAO ABERTA' || _vm.status == 'DIGITADO' || _vm.status == 'CONFIRMADO'  || _vm.status == 'TYPED',
      'naodigitada nao-digitada':  _vm.status.includes('NÃO DIGITADA') || _vm.status.includes('NÃO DIGITADO') || _vm.status == 'AGUARDANDO CONFIRMACAO'  || _vm.status == 'NOT_TYPED',
      'label-dark':    _vm.status == 'SEM PEDIDO' || _vm.status == 'NOT_ORDERED',
      'label-danger':  _vm.status == 'CANCELADA',
      'label-default':  _vm.status == 'P. NÃO TRABALHADOS' || _vm.status == 'NOT_OFFERED',
      'freezed':  (_vm.status == 'CONGELADA' || _vm.status == 'ENVIADA P/ CENTRAL' || _vm.status == 'FROZEN'),
      'label-default': _vm.status == 'COTACAO CONGELADA'}},[_vm._v(" "+_vm._s(_vm.nomeStatus(_vm.status))+" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }