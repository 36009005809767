<template>
    <span :class="participation_class()">
        {{ (value*100.00).toFixed(2) }} %
    </span>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        value: {
            type: Number
        }
    },
    methods: {
        participation_class() {
            if(this.value <= 0.3) {
                return 'shadow low'
            } else if(this.value > 0.7) {
                return 'shadow high'
            } else {
                return 'shadow medium'
            }
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/sass/commons/_variables';
    span {
        white-space: nowrap;
        color: black;
        width: fit-content;
        border-radius: 5px;
        padding: 8px 10px;
        width: 6rem;
        text-align: center;
        display: inline-block;
        &:hover {
            background: #4AC959;
            cursor: default;
            color: white;
            border-radius: 5px;
            i {
                color: white;
            }
        }
        &.high {
            color: white;
            background-color: #278E3F;
        }
        &.low {
            color: white;
            background-color: #CB4243;
        }
        &.medium {
            color: white;
            background-color: $primary-color;
        }
    }
</style>
