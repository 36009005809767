<template>
  <div id="app">
    <MobileMenu
      :mobileMenuIsExpanded="mobileMenuIsExpanded"
      :setMobileMenu="setMobileMenu"
    />
    <div id="removePreVue">
      <cc-header v-if="logged" :setMobileMenu="setMobileMenu"></cc-header>
      <cc-sidebar v-if="logged"></cc-sidebar>
      <transition name="fade" mode="out-in">
        <router-view class="page-body" />
      </transition>
    </div>
    <NotificationContainer />
  </div>
</template>

<script>
import Header from "@/components/cliente/base-components/Header";
import Sidebar from "@/components/shared/Sidebar";
import NotificationContainer from "@/components/notifications/NotificationContainer";
import MobileMenu from "@/components/shared/MobileMenu.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import {setFaviconAndTitle} from "./helpers/docInfo";
export default {
  components: {
    NotificationContainer,
    ccHeader: Header,
    ccSidebar: Sidebar,
    MobileMenu,
  },
  computed: {
    ...mapGetters({ logged: "user/logged" }),
    ...mapState(["whitelabel"]),
  },
  data() {
    return {
      mobileMenuIsExpanded: false,
    };
  },
  methods: {
    ...mapActions(["fetchDados"]),
    checkAndSetFaviconAndTitle() {
      if (
        this.whitelabel.data.imgs.img_window != null &&
        this.whitelabel.data.providers.text_window != null
      ) {
        setFaviconAndTitle(this.whitelabel.data.imgs.img_window,this.whitelabel.data.providers.text_window);
      }
    },
    attColorWhitelabel() {
      const primaryColor = this.whitelabel.data.colors.primary || "#FF7110";
      const secondaryColor = this.whitelabel.data.colors.secondary || "#fb6000";
      document.documentElement.style.setProperty(
        "--primary-color",
        primaryColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        secondaryColor
      );
      document.documentElement.style.setProperty(
        "--primary-color-opacity",
        primaryColor + 25
      );
      document.documentElement.style.setProperty(
        "--secondary-color-opacity",
        secondaryColor + 26
      );
    },
    setMobileMenu(value) {
      this.mobileMenuIsExpanded = value;
    },
  },
  created() {
    document.getElementById("preVue").style.display = "none";
    document.body.style.zoom = "80%";
    this.fetchDados().then(() => {
      this.checkAndSetFaviconAndTitle();
      this.attColorWhitelabel();
    });
  },
};
</script>

<style lang="scss">
@import "./sass/app.scss";
.hidden {
  display: none !important;
}
#app {
  overflow-x: hidden;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.3s ease-out;
}
.page-body {
  margin-left: 110px;
  padding-top: 12vh;
  min-height: 100vh;
}
@media only screen and (max-width: 630px) {
  .page-body {
    margin-left: 5vw;
    padding-top: 8vh;
  }
}
</style>
