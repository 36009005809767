import Vue from 'vue'
import Vuex from 'vuex'
import Vue2Filters from 'vue2-filters'
//import VueRouter from 'vue-router'
import axios from 'axios'
import lodash from 'lodash'
// import VueHighcharts from 'vue-highcharts'
import VueMask from 'v-mask'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

window.Vue = Vue
Vue.use(Vuex)
//Vue.use(VueRouter)
Vue.use(Vue2Filters)
// Vue.use(VueHighcharts)
Vue.use(VueMask)

window.axios = axios
window._ = lodash

//window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.Laravel.csrfToken
//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
