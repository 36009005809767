<template>
    <div v-if="notification.type == 'error'" class="m-3 alert alert-danger" role="alert" :class="notification_class">
        <p class="m-0">
           <i class="fas fa-exclamation-triangle mr-2"></i>
           {{ notification.message }}
        </p>
    </div>
    <div v-else class="m-3 alert alert-success" role="alert" :class="notification_class">
        <p class="m-0">
           <i class="fas fa-check mr-2"></i>
           {{ notification.message }}
        </p>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            timeout: null
        }
    },
    mounted() {
        this.timeout = setTimeout(() => this.remove(this.notification), 5000)
    },
    beforeDestroy() {
        clearTimeout(this.timeout)
    },
    computed: {
        notification_class() {
            return `-text-${this.notification.type}`
        }
    },
    methods: mapActions('notification', ['remove'])
}
</script>

<style scoped lang="scss">

@import '@/sass/commons/_variables';

.notification-bar {
    margin: 1.2em;
}
.-text-success {
    background-color: $ternary-color !important;
    color: white;
}
</style>
