<template>
  <div class="w-100">
    <label for="" v-if="label">{{ label }}</label>
    <div class="input-group">
        <input type="text" class="form-control " :value="value" @input="updateValue" v-bind="$attrs" @keyup="keyup">
        <div v-if="!noicon" class="input-group-append">
            <button class="btn" :class="theme" type="button" @click="search"><i :class="icon" aria-hidden="true"></i></button>
        </div>
    </div>
  </div>
</template>
<style scoped>
    input:focus {
        outline: none;
        box-shadow: none;
    }
</style>
<script>
export default {
    inheritAttrs: false,
    props: {
        label: {
            type: String
        },
        value: [Number, String],
        noicon: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: 'fas fa-search'
        },
        theme: {
            type: String,
            default: ''
        }
    },
    methods: {
        keyup() {
            this.$emit('keyup', event.target.value)
        },
        updateValue(event) {
            this.$emit('input', event.target.value)
        },
        search() {
            this.$emit('search')
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';
    button {
        background-color: $primary-color;
        color: white;
        i {
            color: white;
            margin: auto;
            margin-top: 2px;
        }
        &:focus {
            outline: none;
            box-shadow: none;

        }
    }
    .input-group-append {
        margin-left: -5px;
    }
    ::placeholder {
        color: $input-font-color !important;
    }
    input {
        padding: 25px !important;
        font-family:"Source Sans Pro", sans-serif !important;
        color: $input-font-color !important;
        text-align: left;
        font-size: 17px;
        opacity: 0.6;
        min-height: 40px;
        border: 1px solid $border-color;
        &:focus {
            z-index: 1 !important;
            border: 1px solid $border-color !important;
        }
    }
</style>
