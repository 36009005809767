import TokenService from './TokenService';

export default class Service {

  getHeaders() {
      
    const tokenService  = new TokenService();

    let headers = {};

    if ( tokenService.exists() !== true ) {
      headers = {
        'Authorization' : 'Bearer ' + tokenService.get()
      };
    }

    return headers;
  }

}
