export const uiMixin = {
    inheritAttrs: false,
    props: {
        value: [String, Number, Array, Object, Boolean],
        label: String
    },
    methods: {
        updateValue(event) {
            this.$emit('input', event.target.value)
        }
    }
}