<template>
    <div class="loading">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <span>{{ text || 'Carregando' }}</span>
    </div>
</template>
<style lang="sass" scoped>
    @import './loader.component.scss';
</style>
<script>
export default {
    props: {
        text: [ String ]
    }
}
</script>