<template>
    <span v-on="$listeners" v-bind="$attrs">
        {{ content }}
        <i class="fa" :class="direction_icon"></i>
    </span>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        content: {
            type: String
        },
        direction: {
            type: Number,
            default: null
        },
        showicon: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        direction_icon() {
            if(!this.showicon)return 'fa-sort'
            if(this.direction == 1)return 'fa-sort-asc'
            if(this.direction == 0)return 'fa-sort-desc'
        }
    }
}
</script>

<style scoped lang="scss">
    span {
        cursor: pointer;
        i {
            font-size: 1.3rem;
            position: relative;
            top: 0.4rem;
            margin-left: 0.3rem;
            &.fa-sort-asc {
                color: green;
            }
            &.fa-sort-desc {
                color: brown;
                top: -0.2rem;
            }
            &.fa-sort {
                font-size: 0.8rem;
                top: -0.05rem;
            }
        }
    }
</style>