let next_id = 1

export default {
    namespaced: true,
    state: {
        notifications: []
    },
    mutations: {
        PUSH(state, notification) {
            state.notifications.push({
                id: next_id++,
                ...notification
            })
        },
        DELETE(state, notification) {
            state.notifications = state.notifications.filter(not => {
                not.id !== notification.id
            })
        }
    },
    actions: {
        add({ commit }, notification) {
            commit('PUSH', notification)
        },
        remove({ commit }, notification) {
            commit('DELETE', notification)
        }
    }
}
