<style lang="scss">
.select {
  position: relative;
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-left: 3px solid #A4AFB7;
    border-top: 3px solid #A4AFB7;
    transform: rotate(-135deg);
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &.disabled {
    select {
      background-color: #ECEDEF !important;
    }
  }
  select {
    font-size: 16px;
    font-weight: 400;
    color: #4D4F5C;
    height: 45px;
    width: 194px;
    border: 1px solid #D7DAE2;
    border-radius: 5px;
    margin-left: 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    padding-left: 5px;
  }
  select::-ms-expand {
    display: none;
  }
}
</style>
<template>
  <div class="select" v-bind:class="classes">
    <select v-bind:selected="value" v-bind:disabled="disabled" v-on:change="$emit('change', $event.target.value)"  v-bind:id="id" v-bind:name="name">
      <option 
        v-for="option in options" 
        v-bind:key="option.value + Math.random()" 
        v-bind:value="option.value"
        v-bind:selected="value == option.value">
        {{option.name}}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    'id' : {
      default: ''
    }, 
    'name' : {
      default: ''
    }, 
    'disabled' : {
      default: false
    }, 
    'options' : {
      default: []
    }, 
    'classes' : {
      default: ''
    }, 
    'value' : {
      default: ''
    }
  },
  data() {
    return {
    };
  }
}
</script>