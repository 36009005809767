<template>
    <div class="w-100">
        <label for="" v-if="label">{{ label }}</label>
        <multiselect
            v-bind="$attrs"
            v-on="$listeners"
            :value="value"
            deselect-label="remover"
            :track-by="tracker"
            @input="input"
            :label="text"
            :id="id"
            :placeholder="placeholder"
            selected-label="Selecionado"
            :options="options"
            open-direction="below"
            :searchable="searchable"
            :close-on-select="closeonselect"
            :allow-empty="empty"
            :show-pointer="true"
            :show-labels="false"
            :multiple="multiple" />
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import { uiMixin } from '@/mixins/base.mixing'
  export default {
    mixins: [ uiMixin ],
    props: {
        closeonselect: {
          type: Boolean,
          default: true
        },
        searchable: {
          type: Boolean,
          default: false
        },
        empty: {
          type: Boolean,
          default: true
        },
        options: {
            type: Array,
            required: true
        },
        id: {},
        value: {
            required: true
        },
        tracker: {
          type: String,
          default: 'key'
        },
        text: {
          type: String,
          default: 'value'
        },
        placeholder: {
          type: String,
          default: ''
        },
        multiple: {
          type: Boolean,
          default: false
        },
        label: String
    },
    methods: {
      input(selection) {
          this.$emit('input', selection)
      }
    },
    components: { Multiselect }
  }
</script>

<style lang="scss" scoped>
  @import "@/sass/commons/_variables";

	/deep/ {
    html {
      overflow: scroll;
      overflow-x: hidden;
    }
    ::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
    }
    .multiselect {
      font-family:"Source Sans Pro", sans-serif !important;
      height: max-content;
    }
    .multiselect__single, .multiselect__placeholder {
      color: $input-font-color !important;
    }
    .multiselect__option {
      text-transform: uppercase !important;
      &.multiselect__option--highlight {
        background-color: #898989 !important;
      }
    }
    .multiselect__tag {
      background-color: #007bff !important;
    }
    .multiselect__tags {
        padding: 5px 10px 10px 10px;
        border: 1px solid #E5E5E5;
        font-size: 14px;
        overflow-y: auto;
    }
    .multiselect__tag-icon {
      background-color: #007bff !important;
    }
    .multiselect__option {
      padding: 5px;
      min-height: 30px;
      font-size: 12px !important;
    }
  }

</style>
