<style lang="scss">
  @import '@/sass/commons/_variables';

ol.breadcrumb {
  padding: 0;
  margin-bottom: 0;
}
.breadcrumb-item+.breadcrumb-item::before {
  color: $primary-color !important;
}
.breadcrumb {
  background-color: white !important;
  padding: 0.35rem 1rem;
  font-size: 0.8rem;
  text-transform: uppercase;
  .breadcrumb-item { 
    a {
      color: $primary-color !important;
    }
    &.active {
      color: $primary-color !important;
    }
  }
}

ul{
  padding-top: 20px !important
}
::placeholder {
  
  padding:15px;
}
.linkCrumb{
  font-weight: bold !important;
  color: white !important;

}
</style>
<template>
	<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><router-link :to="{ name : 'home' }">Home</router-link></li>
      <li class="breadcrumb-item" v-for="crumb in breadcrumbs" :key="crumb.name"><router-link :to="{ name: crumb.route }">{{ crumb.name }}</router-link></li>
    </ol>
  </nav>
</template>

<script>
import * as CONSTANTS from "@/constants/constants";
import { mapState } from 'vuex'

export default {
  props: ['classes'],
  data() {
    return {
      CONSTANTS: CONSTANTS
    };
  },
  computed: mapState(['breadcrumbs'])
}
</script>