import { mapGetters } from 'vuex'

export const perm_mixin = {
    data() {
        return {
        }
    },
    methods: {
        can(resource, action) {
            return (!this.perms || this.perms.length == 0) ||
                this.perms.some(perm => perm.resource == resource && perm.action == action) ||
                this.perms.some(perm => perm.resource == "ADMIN" && perm.action == "MANAGE")
        }
    },
    computed: {
        ...mapGetters({ perms: 'user/perms', is_reseller: 'user/is_reseller' }),
    },
    mounted() {
        this.$store.dispatch('user/load_perms')
    }
}
