<template>
    <div class="form-check form-check-inline mr-0">
        <input :value="value" class="form-check-input"
               type="checkbox"
               @change="$emit('input', $event.target.checked)"
               v-on="$listeners"
               v-bind="$attrs">
        <span v-if="label" class="form-check-label">{{ label }}</span>
    </div>
</template>

<script>
import { uiMixin } from '@/mixins/base.mixing'
export default {
    mixins: [ uiMixin ]
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';
    input[type=checkbox] {
        transform: scale(1.8); /* Opera */
        padding: 10px;
    }

    .form-check {
        display: flex;
        align-items: center;
        span {
            padding: 0.2rem;
        }
    }

</style>
