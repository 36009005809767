import Vuex from 'vuex'
import QuotationService from "@/services/QuotationService"
import ErrorHandlerService from '@/services/ErrorHandlerService'
import user from './modules/user.module'
import permissions from "./modules/permissions.module";
import notification from './modules/notification.module'
import whitelabel from './modules/whitelabel.module'
export const store = new Vuex.Store({
    modules: { user, notification, whitelabel, permissions },
    state: {
        tab: "REQUEST_ORDER",
        clientesFiltro: [],
        vendedoresFiltro: [],
        statusFiltro: [],
        cotacoes: null,
        minhasCotacoes: null,
        outrasCotacoes: null,
        pedidos: null,
        tabRelatorioCliente: 1,
        categorias: null,
        vendedores: null,
        vendedorEdit: [],
        itensVendedores: null,
        clienteEdit: null,
        dateIniRelatorio: moment().format("YYYY-MM-DD"),
        dateFimRelatorio: moment().format("YYYY-MM-DD"),
        supervisorEdit: null,
        current_user: {},
        categoriasFiltro: [],
        fornecedorFiltro: [],
        requisitantesFiltro: [],
        breadcrumbs: [],
        statuses: [],
        VIEW: null,
        directSale: 0,
        productBrandsInfo: undefined
    },
    actions: {
        set_breadcrumbs({ commit }, breadcrumbs) {
            commit("SET_BREADCRUMBS", breadcrumbs);
        },
        set_view({ commit }, view) {
            commit("SET_VIEW", view);
        },
        set_current_user({ commit }, current_user) {
            commit("SET_CURRENT_USER", current_user);
        },
        set_statuses({ commit, state }) {
            if (state.statuses && state.statuses.length > 0) {
                return state.statuses;
            } else {
                var quotationService = new QuotationService();
                quotationService
                    .getAllStatus()
                    .then(response => response.data)
                    .then(data => {
                        var status = [{ value: "", name: "Todas" }];
                        for (var i = 0; i < data.status.length; i++) {
                            if (
                                data.status[i].status_nome !==
                                "CANCELADA" &&
                                data.status[i].status_nome !==
                                "SEM PEDIDOS PARA VOCE"
                            ) {
                                var nome = data.status[i].status_nome;
                                status.push({
                                    value: data.status[i].status_id,
                                    name:
                                        nome.charAt(0).toUpperCase() +
                                        nome.slice(1).toLowerCase()
                                });
                            }
                        }
                        commit("SET_STATUSES", status);
                    })
                    .catch(error => {
                        ErrorHandlerService.handle(error, this.$store);
                    });
            }
        }
    },
    mutations: {
        setProductBrands(state, brandInfo) {
            state.productBrandsInfo = brandInfo
        },
        SET_CURRENT_USER(state, current_user) {
            state.current_user = current_user;
        },
        SET_DIRECT_SALE(state, sale) {
            state.directSale = sale;
        },
        SET_VIEW(state, view) {
            state.VIEW = view;
        },
        SET_STATUSES(state, statuses) {
            state.statuses = statuses;
        },
        SET_BREADCRUMBS(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
        changeDateIniRelatorioCliente(state, date) {
            state.dateIniRelatorio = date;
        },
        changeDateFimRelatorioCliente(state, date) {
            state.dateFimRelatorio = date;
        },
        changeTabRelatorioCliente(state, tab) {
            state.tabRelatorioCliente = tab;
        },
        setCategoriaFiltroRelatorio(state, categorias) {
            state.categoriasFiltro = categorias;
        },
        setFornecedoresFiltroRelatorio(state, fornecedores) {
            state.fornecedorFiltro = fornecedores;
        },
        setRequisitantesFiltroRelatorio(state, requisitantes) {
            state.requisitantesFiltro = requisitantes;
        },
        setUsuario(state, usuario) {
            state.usuario = usuario;
        },
        setUsuarioNome(state, usuario_nome) {
            state.usuario_nome = usuario_nome;
        },
        setCotacaoId(state, cotacao_id) {
            state.cotacao_id = cotacao_id;
        },
        setStatusFormatado(state, status_formatado) {
            state.status_formatado = status_formatado;
        },
        setVendedorEdit(state, vendedor) {
            state.vendedorEdit = vendedor;
        },
        setSupervisorEdit(state, supervisor) {
            state.supervisorEdit = supervisor;
        },
        setClienteEdit(state, cliente) {
            state.clienteEdit = cliente;
        },
        salvarVendedoresFiltro(state, vendedores) {
            state.vendedoresFiltro = vendedores;
        },
        salvarStatusFiltro(state, status) {
            state.statusFiltro = status;
        },
        salvarClientesFiltro(state, clientes) {
            state.clientesFiltro = clientes;
        },
        salvarMinhasCotacoes(state, dados) {
            state.minhasCotacoes = {
                cotacoes: dados.cotacoes,
                paginaAtual: dados.paginaAtual,
                totalPaginas: dados.totalPaginas,
                itensPorPagina: dados.itensPorPagina,
                totalItens: dados.totalItens
            };
        },
        salvarCotacoes(state, dados) {
            state.cotacoes = {
                cotacoes: dados.cotacoes,
                paginaAtual: dados.paginaAtual,
                totalPaginas: dados.totalPaginas,
                itensPorPagina: dados.itensPorPagina,
                totalItens: dados.totalItens
            };
        },
        salvarOutrasCotacoes(state, dados) {
            state.outrasCotacoes = {
                cotacoes: dados.cotacoes,
                paginaAtual: dados.paginaAtual,
                totalPaginas: dados.totalPaginas,
                itensPorPagina: dados.itensPorPagina,
                totalItens: dados.totalItens
            };
        },
        salvarPedidos(state, dados) {
            state.pedidos = {
                pedidos: dados.pedidos,
                paginaAtual: dados.paginaAtual,
                totalPaginas: dados.totalPaginas,
                itensPorPagina: dados.itensPorPagina,
                totalItens: dados.totalItens
            };
        },
        salvarCategorias(state, dados) {
            state.categorias = {
                categorias: dados.categorias,
                paginaAtual: dados.paginaAtual,
                totalPaginas: dados.totalPaginas,
                itensPorPagina: dados.itensPorPagina,
                totalItens: dados.totalItens
            };
        },
        salvarVendedores(state, dados) {
            state.vendedores = {
                vendedores: dados.vendedores,
                paginaAtual: dados.paginaAtual,
                totalPaginas: dados.totalPaginas,
                itensPorPagina: dados.itensPorPagina,
                totalItens: dados.totalItens
            };
        },
        salvarItensVencedores(state, dados) {
            state.itensVencedores = {
                itensVencedores: dados.itensVencedores,
                paginaAtual: dados.paginaAtual,
                totalPaginas: dados.totalPaginas,
                itensPorPagina: dados.itensPorPagina,
                totalItens: dados.totalItens
            };
        },
        salvarItensNaoVencedores(state, dados) {
            state.itensNaoVencedores = {
                itensNaoVencedores: dados.itensNaoVencedores,
                paginaAtual: dados.paginaAtual,
                totalPaginas: dados.totalPaginas,
                itensPorPagina: dados.itensPorPagina,
                totalItens: dados.totalItens
            };
        },
        salvaritensVendedores(state, dados) {
            state.itensVendedores = {
                itensVendedores: dados.itensVendedores,
                paginaAtual: dados.paginaAtual,
                totalPaginas: dados.totalPaginas,
                itensPorPagina: dados.itensPorPagina,
                totalItens: dados.totalItens
            };
        },
        setTabRequestOrder(state) {
            state.tab = "REQUEST_ORDER";
        },
        setTabLead(state) {
            state.tab = "LEAD";
        },
        setTabDirectPurchase(state) {
            state.tab = "DIRECT_PURCHASE";
        },
        setTabContracts(state) {
            state.tab = "CONTRACTS";
        }
    },
    getters: {
        usuario: state => state.usuario,
        view: state => state.VIEW,
        usuario_nome: state => state.usuario_nome,
        cotacao_id: state => state.cotacao_id,
        status_formatado: state => state.status_formatado,
        direct_sale: state => state.directSale
    }
});
