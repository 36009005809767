import "./bootstrap";
import App from './App.vue';
import router from "./routes";
import { store } from "./store/store";
import Vue from "vue";
import VueCarousel from "vue-carousel";
import "vue-multiselect/dist/vue-multiselect.min.css";
import 'sweetalert2/dist/sweetalert2.min.css';
import 'hover.css/css/hover.css';
import { ValidationProvider, localize } from 'vee-validate';
import Notifications from "vue-notification";
import VueVirtualScroller from 'vue-virtual-scroller'
import VueHtmlToPaper from 'vue-html-to-paper';
import Datetime from 'vue-datetime'
import VTooltip from 'v-tooltip'
import 'vue-datetime/dist/vue-datetime.css'
import numeral from "numeral";
import moment from "moment";
import VueSweetalert2 from "vue-sweetalert2";
import BootstrapVue from 'bootstrap-vue'
import VModal from 'vue-js-modal'
import StarRating from 'vue-star-rating'
import VueCurrencyFilter from 'vue-currency-filter'
import ToggleButton from 'vue-js-toggle-button'
import Multiselect from 'vue-multiselect'
import Select from "@/components/cliente/base-components/Select";
import Button from '@/components/ui/buttons/button.component'
import Modal from '@/components/ui/modals/modal.component'
import Loader from '@/components/ui/loaders/loader.component'
import Whatsapp from '@/components/ui/labels/whatsapp.component'
import Percentage from '@/components/ui/labels/percentage.component'
import FullScreenLoader from '@/components/ui/loaders/loader-full-screen.component'
import Status from "@/components/shared/Status";
import SelectV2 from '@/components/ui/selects/select.component'
import TextInput from '@/components/ui/inputs/text.component'
import SortableLable from '@/components/ui/labels/sortable-label.component'
import Checkbox from '@/components/ui/inputs/checkbox.component'
import Logo from "@/components/ui/images/logo.component";
import SearchInput from '@/components/ui/inputs/search.component'
import MobileNote from '@/components/notes/mobile-promotion.note';
import NoDataAvailable from "@/components/utils/no-data.utils"
import DropDownButton from "@/components/ui/buttons/dropdown-button.component"
import Popover from "@/components/ui/popovers/base.popover"
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import { extend } from 'vee-validate';
import { Validator } from 'vee-validate';
import VueEllipseProgress from 'vue-ellipse-progress';

extend('phone', {
    message: 'Telefone inválido.',
    validate: value => {
        value = value.replace("(", "")
        value = value.replace(")", "")
        value = value.replace("-", "")
        value = value.replace(" ", "").trim()
        if (value == '0000000000')
            return false
        else if (value == '00000000000')
            return false

        if (["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10"]
        .indexOf(value.substring(0, 2)) != -1)
            return false

        if (value.length < 10 || value.length > 11)
            return false

        if (["6", "7", "8", "9"].indexOf(value.substring(2, 3)) == -1)
            return false

        return true
    }
})

extend("cnpj", {
    params: ["target"],
    validate(value) {
        return validarCNPJ(value);
    },
    message: "CNPJ inválido!"
});

function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999"
    )
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;

    return true;
}


extend('required', {
    validate (value) {
        return {
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1
        };
    },
    computesRequired: true
});

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Senha e confirmação de senha diferem!'
});

extend('profile', {
  params: ['target'],
  validate(value, { target }) {
      return 'LOJA' === target.pfl_descricao && value !== null || (!target || target.pfl_descricao != 'LOJA');
  },
  message: 'Selecione uma empresa!'
});

// register globally
Vue.use(VueCarousel);

Vue.component('multiselect', Multiselect)
Vue.component('cc-button', Button);
Vue.component('cc-modal', Modal);
Vue.component("cc-logo", Logo);
Vue.component('cc-whatsapp', Whatsapp);
Vue.component('cc-percentage', Percentage);
Vue.component('cc-select', Select);
Vue.component('cc-checkbox', Checkbox);
Vue.component('cc-select-v2', SelectV2);
Vue.component('cc-status', Status);
Vue.component('cc-loader', Loader);
Vue.component('cc-loader-full', FullScreenLoader);
Vue.component('cc-input', TextInput);
Vue.component('cc-label-sort', SortableLable);
Vue.component('cc-search', SearchInput);
Vue.component('cc-mobile-note', MobileNote);
Vue.component('no-data-available', NoDataAvailable);
Vue.component('cc-dropdown-button', DropDownButton);
Vue.component('cc-popover', Popover);

import { Settings } from "luxon";
moment.locale("pt-br");
// window.$ = window.jQuery = require("jquery");
Settings.defaultLocale = "pt";

numeral.register("locale", "pt", {
  delimiters: {
    thousands: ".",
    decimal: ","
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t"
  },
  ordinal: function(number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "R$"
  }
});
numeral.locale("pt");

const dictionary = {
  en: {
    messages: {
      email: function() {
        return "Inserir email válido";
      },
      required: function() {
        return "Campo obrigatório";
      }
    }
  }
};

localize(dictionary);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use(ToggleButton)
Vue.use(VueEllipseProgress);
Vue.use(VTooltip)

Vue.use(VueCurrencyFilter, {
    symbol : 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
})

Vue.use(Notifications);

Vue.use(VueVirtualScroller)

Vue.use(VueSweetalert2);

Vue.use(Datetime);

Vue.use(VueHtmlToPaper, {
	styles: [
		"./sass/app.scss",
        "./sass/reports/general-report-matrix.scss"
	]
});

// Vue.filter('VMask', VueMaskFilter)
Vue.filter("toCurrency", function(value) {
  if (typeof value !== "number") {
    return value;
  }
  // var formatter = new Intl.NumberFormat("pt-BR");
  return numeral(value).format("0,0[.]00");
});

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString().toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('cnpj', function (value) {
	if (!value) return ''
	value = value.toString()
	value = value.slice(0, 2) + "." + value.slice(2);
	value = value.slice(0, 6) + "." + value.slice(6);
	value = value.slice(0, 10) + "/" + value.slice(10);
	value = value.slice(0, 15) + "-" + value.slice(15);
	return value;
  })

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY HH:mm");
  }
});

Vue.filter("formatDateOnly", function(value) {
	if (value) {
	  return moment(String(value)).format("DD/MM/YYYY");
	}
});

const url = window.location.href;
const name = "token";
const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
let results = regex.exec(url);
if (results) {
  const token = decodeURIComponent(results[2].replace(/\+/g, " "));
  localStorage.setItem("token", token);
}
Vue.use(VueCurrencyFilter,
  {
    symbol : 'R$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  })

Vue.use(BootstrapVue)
Vue.use(VModal)
Vue.use(require('vue-moment'));
Vue.use(Donut);
Vue.component('star-rating', StarRating);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
