<template>
  <div v-if="mobileMenuIsExpanded" class="mobilemenu-page-container">
    <div class="mobilemenu-page">
      <div class="mobilemenu-header-containear">
        <div style="display: flex; align-items: center;">
          <span class="material-icons account-icon">account_circle</span>
          <div class="mobilemenu-user-name">{{user.user_name}}</div>
        </div>
        <span v-on:click="closePage()" class="material-icons-round">
          close
        </span>
      </div>
      <div class="mobilemenu-header-end-line"></div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/clientes/acompanhamentos')">
        <span class="material-icons">person</span>
        <div style="margin-left: 20px">Acompanhamento</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/clientes')">
        <span class="material-icons">group</span>
        <div style="margin-left: 20px">Clientes</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/fornecedores')">
        <span class="material-icons">groups</span>
        <div style="margin-left: 20px">Fornecedores</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/pedidos')">
        <span class="material-icons">add_shopping_cart</span>
        <div style="margin-left: 20px">Vendas Direta</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/resales')">
        <span class="material-icons">shopping_basket</span>
        <div style="margin-left: 20px">Revendas</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/convites')">
        <span class="material-icons">mail</span>
        <div style="margin-left: 20px">Convites</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/produtos')">
        <span class="material-icons">shopping_bag</span>
        <div style="margin-left: 20px">Produtos Padrão</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/cliente/monitores/cotacoes')">
        <span class="material-icons">desktop_windows</span>
        <div style="margin-left: 20px">Painel de Operações</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/settings')">
        <span class="material-icons">settings</span>
        <div style="margin-left: 20px">Configurações do Sistema</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="redirect('/revenda/configuracoes')">
        <span class="material-icons">manage_accounts</span>
        <div style="margin-left: 20px">Configurações da Conta</div>
      </div>
      <div class="mobilemenu-icons-containear" v-on:click="logout()">
        <span class="material-icons">logout</span>
        <div style="margin-left: 20px">Sair</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as CONSTANTS from "@/constants/constants";
export default {
  name: 'MobileMenu',
  props: ['setMobileMenu', 'mobileMenuIsExpanded'],
  computed: {
    ...mapState([ 'user' ]),
  },
  methods: {
    redirect(page){
      this.$router.push(page)
      this.closePage()
    },
    closePage(){
      this.setMobileMenu(false)
    },
    logout() {
      this.$store.dispatch('user/logout')
      location.href = CONSTANTS.ROUTE_LOGIN;
    },
  }
}
</script>

<style scoped>
  .mobilemenu-page{
    padding: 30px;
    background-color: white;
    width: 80%;
    height: 125vh;
    border-radius: 0 8px 8px 0;
  }
  .mobilemenu-page-container{
    display: none;
  }
  .mobilemenu-user-name{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--primary-color);
  }
  .mobilemenu-header-containear{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobilemenu-header-end-line{
    border-bottom: 1px solid #E5E5E5;
    margin-right: 25%;
    margin-top: 20px;
  }
  .mobilemenu-icons-containear{
    display: flex;
    align-items: center;
    margin-top: 35px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.15px;
    color: #ABABAB;
  }
  .mobilemenu-icons-package-img{
    filter: brightness(0) saturate(100%) invert(74%) sepia(0%) saturate(18%) hue-rotate(195deg) brightness(95%) contrast(86%);
    width: 25px;
  }
  .account-icon{
    font-size: 35px;
    color: #ABABAB;
    margin-right: 15px;
  }
  .mobilemenu-body-end-line{
    border-bottom: 1px solid #E5E5E5;
    margin-top: 100px;
  }
  .tagnew-icon{
    position: absolute;
    background-color: #30AA4C;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    padding: 0.5vh;
    border-radius: 5px;
    margin-top: -20px;
    margin-left: 70vw;
}
.material-icons-round {
  color: #898989; 
  font-size: 36px;
}
  @media only screen and (max-width: 630px) {
    .mobilemenu-page-container{
      display: block;
      position: fixed;
      background: rgba(0,0,0,0.5);
      z-index: 4;
      width: 125vw;
      height: 125vh;
    }
  }
</style>