import StorageService from "@/services/local-storage.service";
import AuthService from '@/services/AuthService'
import ProfileService from '@/services/v3/profile.service'
import StoresService from '@/services/StoresService'

export default {
    namespaced: true,
    state: {
        user_name: null,
        token: null,
        request_type: null,
        settings: null,
        stores: null,
        perms: [],
        is_reseller: null
    },
    actions: {
        reload_user({ commit }) {
            return new AuthService().get_user().then(response => response.data).then(data => {
                commit('SET_SETTINGS', data.usuario.settings)
                commit('SET_IS_RESELLER', !!data.usuario.revenda_id)
            })
        },
        load_perms({ commit }) {
            let perms = StorageService.get('perms')
            if(perms) {
                commit('SET_PERMISSIONS', perms)
            } else {
                return new ProfileService().my().then(({ data }) => {
                    commit('SET_PERMISSIONS', data)
                    StorageService.set('perms', data)
                })
            }
        },
        load_user({ commit }) {
            commit('SET_USER_NAME', StorageService.get_string('user_name'))
            commit('SET_TOKEN', StorageService.get_string('token'))
            commit('SET_SETTINGS', StorageService.get('user_settings'))
            commit('SET_STORES', StorageService.get('stores'))
            commit('SET_GROUP_NAME', StorageService.get_string('grupo'))
            commit('SET_CLI_ID', StorageService.get('cli_id'))
            commit('SET_CLI_REQUEST_TYPE', StorageService.get('request_type'))
            commit('SET_PERMISSIONS', StorageService.get('perms'))
            commit('SET_CLOSING_METHOD', StorageService.get_string('closing'))
            commit('SET_CLOSING_METHOD', StorageService.get_string('closing'))
            commit('SET_IS_RESELLER', !!StorageService.get('revenda'))
        },
        logout({ commit }) {
            commit('SET_USER_NAME', null)
            commit('SET_TOKEN', null)
            commit('SET_PERMISSIONS', null)
            localStorage.removeItem('tcp_id')
            localStorage.removeItem('cli_id')
            localStorage.removeItem('seen_warnings_request_ids')
            localStorage.removeItem('stores')
            localStorage.removeItem('has_vf_integration')
            localStorage.removeItem('user_name')
            localStorage.removeItem('user_settings')
            localStorage.removeItem('token')
            localStorage.removeItem('perms')
            localStorage.removeItem('revenda')
        },
        async fetch_stores({ commit }, cli_id) {
            console.log(cli_id);
            let response = await new StoresService().getStores(cli_id)
            let stores = response.data.lojas
            commit('SET_STORES', stores)
            return stores
        }
    },
    mutations: {
        SET_USER_NAME(state, user_name) {
            state.user_name = user_name
        },
        SET_GROUP_NAME(state, group) {
            state.group = group
        },
        SET_CLOSING_METHOD(state, method) {
            state.method = method
        },
        SET_STORES(state, stores) {
            state.stores = stores
        },
        SET_SETTINGS(state, settings) {
            state.settings = settings
        },
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_CLI_REQUEST_TYPE(state, type) {
            state.request_type = type
        },
        SET_CLI_ID(state, cli_id) {
            state.cli_id = cli_id
        },
        SET_PERMISSIONS(state, perms) {
            state.perms = perms
        },
        SET_IS_RESELLER(state, is_reseller) {
            state.is_reseller = is_reseller
        }
    },
    getters: {
        logged: state => {
            return !!state.token
        },
        setting: state => (key) => {
            if(!state.settings)return null;
            return state.settings.find(s => s.key == key)
        },
        stores: state => {
            return state.stores
        },
        perms: state => {
            return state.perms
        },
        is_reseller: state => {
            return state.is_reseller
        }
    }
}
