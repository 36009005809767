
<template>
    <img class="logo" v-on="$listeners" v-bind="$attrs">
</template>

<script>
export default {
    inheritAttrs: false,
    props: [],
    methods: {
        execute_on_click() {
            this.$emit('click');
        }
    }
};
</script>
<style lang="scss" scoped>

@import "@/sass/commons/_variables";

.logo {
    width: 20px !important;
    height: 20px;
    cursor: pointer;
}

</style>
