<template>
  <router-link :to="link || ''">
    <b-dropdown v-on="$listeners" v-bind="$attrs" block>
        <template v-slot:button-content>
            <span v-if="content"><i v-if="icon" :class="icon"></i>{{ content }}</span>
            <i v-if="icon && !content" :class="icon"></i>
        </template>
        <b-dropdown-item v-for="(option, index) in options" @click="option.action()" :key="index">{{ option.label }}</b-dropdown-item>
    </b-dropdown>
  </router-link>
</template>

<script>
export default {
    inheritAttrs: false,
    props: ["content", "classes", "icon", "link", "disabled", "options" ],
    methods: { 
        execute_on_click() {
            this.$emit('click');
        }
    }
};
</script>
<style lang="scss" scoped>

@import "@/sass/commons/_variables";

/deep/ {

    .dropdown-menu.show {
        width: 100%;
        color: $brand-success;
        li {
            text-transform: uppercase;
            font-size: 0.9rem;
            text-align: center;
            a {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
        li a:hover {
            background-color: $brand-success;
            color: white;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    
    button {
        display: flex;
        justify-content: center;
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
        padding-top: 10px;
        padding-bottom: 10px;
        height: fit-content;
        background: transparent;
        color: $brand-success;
        border: 1px solid #e8e9ec;
        border-radius: 5px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        white-space: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 0.9rem;

        &.fixed {
            height: 2.5rem;
            width: 12rem;
        }
        
        i {
            color: $ternary-color;
            font-size: 1rem;
        }

        span i {
            margin-right: 0.6rem;
        }
    
        &:focus {
            outline: none;
        }

        &[disabled] {
            border: 1px solid $disabled-gray;
            color: $disabled-gray;
            filter: opacity(0.5);
            cursor: not-allowed;
            i {
                color: $disabled-gray;
            }
        }

        &:hover, &:focus, &:active, &[aria-expanded=true] {
            color: white;
            background-color: $brand-success !important;
            border: 1px solid $brand-success !important;
            i {
                color: white;
            }
            &[disabled] {
                border: 1px solid $disabled-gray;
                color: $disabled-gray;
                cursor: not-allowed;
                i {
                    color: $disabled-gray;
                }
            }
        }
    
        &.info {
            border: 1px solid #e8e9ec;
            &:hover {
                border: 1px solid #e8e9ec;
            }
        }   
    
        &.danger-outline {
            border: 1px solid $brand-danger;
            color: $brand-danger;
            i {
                color: $brand-danger;
            }
            &:hover {
                background-color: $brand-danger;
                color: white;
                border: 1px solid $brand-danger;
                i {
                    color: white;
                }
            }
        }
    
        &.success {
            background-color: white;
            color: $brand-success;
            border: 1px solid $brand-success;
            i {
                color: $brand-success;
            }
            &:hover {
                color: white;
                background-color: $brand-success;
                border: 1px solid $brand-success;
                i {
                    color: white;
                }
            }
        }

        &.secondary {
            background-color: white;
            color: $blue1;
            i {
                color: $blue1;
            }
            &:hover {
                color: white;
                background-color: $blue1;
                border: 1px solid white;
                i {
                    color: white;
                }
            }
        }

        &.active {
            color: $orange;
            border: 1px solid $orange
        }
    }
}

</style>