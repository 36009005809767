<template>
    <div class="popover-base" v-show="visible">
        <div class="popover-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: [Boolean],
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_variables';
    .popover-base { 
        position: relative
    }
    .popover-content {
        position: absolute;
        background: white;
        border: 1px dashed $secondary-color;
        padding: 9px;
        width: 250px;
        left: 47px;
        border-radius: 4px;
    }
</style>